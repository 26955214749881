import * as React from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../layouts/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { ComponentMotion } from "../utils/componentMotion"
import { Seo } from "../components/seo"

const ShopPage = ({ data }) => {
  const productsContentful = data.productsContentful.nodes
  const isEnglish = data.context.context.nodeLocale === "en-CA"
  const path = data.context.context.pathNoLanguagePrefix

  return (
    <Layout isEnglish={isEnglish} path={path}>
      <div className="">
        <div className="bg-white">
          <div className=" mx-auto overflow-hidden sm:px-6 md:px-0">
            <h2 className="sr-only">Products</h2>
            <ComponentMotion viewThreshold={0} yMotion={0}>
              <div className="-mx-px min-h-[65vh] border-l border-gray-200 grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
                {productsContentful.map((product, i) => (
                  <div
                    key={i}
                    className="group relative p-4 border-r border-b border-gray-200 sm:p-6 flex items-center"
                  >
                    <div className="">
                      <div className="rounded-lg overflow-hidden bg-grayBg aspect-square aspect-w-1 aspect-h-1 group-hover:opacity-75">
                        <GatsbyImage
                          image={product.pictures[0].gatsbyImageData}
                          alt={product.imageAlt}
                          className="h-full w-full object-center object-cover"
                        />
                      </div>
                      <div className="pt-10 pb-4 text-center">
                        <div className="">
                          <Link
                            key={product.id}
                            to={
                              isEnglish
                                ? `/en/product/${product.slug}`
                                : `/product/${product.slug}`
                            }
                            className="group"
                          >
                            <span
                              aria-hidden="true"
                              className="absolute inset-0"
                            />
                            <h2 className="uppercase text-xl tracking-widest font-extrabold text-secondary">
                              {product.title}
                            </h2>
                            {/* <h3 className="mt-3 text-xs md:text-sm italic lg:text-base font-medium text-tertiary">
                              {product.subtitle}
                            </h3> */}
                          </Link>
                        </div>
                        {/* <p className="mt-4 text-base font-medium text-gray-900">{product.price}</p> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </ComponentMotion>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ShopPage

export const Head = ({ data }) => <Seo title={data.page.title} />

export const query = graphql`
  query ShopPage($slug: String!, $nodeLocale: String!) {
    page: contentfulShopPage(
      slug: { eq: $slug }
      node_locale: { eq: $nodeLocale }
    ) {
      id
      node_locale
      title
      slug
    }
    context: sitePage(
      context: { nodeLocale: { eq: $nodeLocale }, slug: { eq: $slug } }
    ) {
      id
      path
      context {
        slug
        nodeLocale
        pathNoLanguagePrefix
      }
    }
    productsContentful: allContentfulProductItem(
      filter: { node_locale: { eq: $nodeLocale } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        node_locale
        title
        # subtitle
        shopifyLink
        slug
        pictures {
          gatsbyImageData
        }
      }
    }
    productsShopify: allShopifyProduct {
      nodes {
        title
        description
        productType
        tags
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        storefrontId
        images {
          # altText
          id
          gatsbyImageData(layout: CONSTRAINED, width: 640, aspectRatio: 1)
        }
        variants {
          availableForSale
          storefrontId
          title
          price
          selectedOptions {
            name
            value
          }
        }
        options {
          name
          values
          id
        }
      }
    }
  }
`
